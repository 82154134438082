
import { defineComponent, ref, onBeforeMount } from 'vue'
import { get } from '@/api/admin/aggregate/sales'
import { ApiAggrResult } from '@/types/api'
import Customer from '@/components/Aggregate/Sales/Customer/index.vue'
import Service from '@/components/Aggregate/Sales/Service/index.vue'
import Selector from '@/components/Aggregate/Sales/Selector.vue'
import TotalPrice from '@/components/Aggregate/Sales/TotalPrice.vue'
import { useArrayRefs, useProgress } from '@/reactives'
import { useTranslation } from '@/locales'

export default defineComponent({
  components: { Customer, Service, Selector, TotalPrice },
  setup() {
    const aggregates = useArrayRefs<ApiAggrResult>()
    const show = ref('Customer')
    const year = ref(2021)
    const month = ref(9)
    const totalPrice = ref(0)
    const purchasePrice = ref(0)
    const { start, done, isProgress } = useProgress(true)
    const { t } = useTranslation()

    const select = (value: string) => {
      show.value = value
    }

    const _getTotal = (prices: ApiAggrResult[]) =>
      prices.reduce(function (accum, val) {
        return accum + 0
      }, 0)

    const _getPurchaseTotal = (prices: ApiAggrResult[]) =>
      prices.reduce(function (accum, val) {
        return accum + 0
      }, 0)

    const changeTerm = (year: number, month: number) => {
      start()
      totalPrice.value = 0
      aggregates.value = []
      get(year, month)
        .then((res) => {
          totalPrice.value = _getTotal(res)
          aggregates.value = [...res]
          done()
        })
        .catch((err) => {
          done()
        })
    }

    onBeforeMount(() => {
      start()
      get()
        .then((res) => {
          totalPrice.value = _getTotal(res)
          purchasePrice.value = _getPurchaseTotal(res)

          if (res.length > 0) {
            year.value = res[0].year
            month.value = res[0].month
          }

          aggregates.value = [...res]
          done()
        })
        .catch((err) => {
          console.log(err)
          done()
        })
    })

    return {
      select,
      show,
      changeTerm,
      aggregates,
      totalPrice,
      purchasePrice,
      year,
      month,
      isProgress,
      t,
    }
  },
})
