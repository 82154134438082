<template>
  <table-frame>
    <template #header>
      <aggregate-tab />
    </template>
    <template #body>
      <aggregate-list :aggregates="converted" />
    </template>
  </table-frame>
</template>

<script lang="ts">
import { defineComponent, toRefs, PropType, watch } from 'vue'
import { ApiAggrResult } from '@/types/api'
import { CAggregate } from '@/types/component'
import { convertAggrResultToUiFormat, swap } from '@/functions/aggregate/index'
import AggregateTab from './AggregateTab.vue'
import AggregateList from '@/components/Aggregate/AggregateList.vue'
import TableFrame from '@/components/Parts/Table/Frame.vue'
import { useArrayRefs } from '@/reactives'
import { useTranslation } from '@/locales'

type Props = {
  aggregate: ApiAggrResult[]
}

export default defineComponent({
  name: 'SalesService',
  components: { AggregateTab, AggregateList, TableFrame },
  props: {
    aggregate: {
      type: Object as PropType<ApiAggrResult[]>,
      required: true,
    },
  },
  setup(props: Props) {
    const { aggregate } = toRefs(props)
    const converted = useArrayRefs<CAggregate>()
    const { t } = useTranslation()

    converted.value = convertAggrResultToUiFormat(aggregate.value, swap)

    watch(aggregate, () => {
      converted.value = convertAggrResultToUiFormat(aggregate.value, swap)
    })

    return { converted, t }
  },
})
</script>
