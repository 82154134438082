
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import Thead from '@/components/Parts/Table/Thead.vue'

export default defineComponent({
  name: 'AggregateTab',
  components: { Thead },
  setup() {
    const { t } = useI18n()

    const tags = [
      'Service Name',
      'Customer',
      'Service Detail',
      'Contract Terms (UTC)',
      'Price for this contract',
      'Deposit date',
      'Sales Terms (JST)',
      'Sales Price with Tax',
      'Subtotal',
      'Total for Sales',
      'Accounts Receivable',
      'Subtotal for Accounts Receivable',
      'Total for Accounts Receivable',
      'Advance Received',
      'Subtotal for Advance Received',
      'Total for Advance Received',
    ]

    return { t, tags }
  },
})
